/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


import React, { useEffect, useRef } from 'react';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // Optional if you want the default styling
import 'tippy.js/themes/light.css';

//Note: if you want to change CSS for the tooltip container, use .tippy-tooltip
//Props info: https://atomiks.github.io/tippyjs/v6/all-props

const CustomTooltip = ({ placement, children, text, maxWidth, className }) => {

    const tooltipRef = useRef(null);

    useEffect(() => {
        const instance = tippy(tooltipRef.current, {
            theme: 'light',
            arrow: false,
            interactive: true,
            appendTo: 'parent', //insures that it will show overtop of dialogs

            //User changeable
            content: text,
            placement: placement,
            maxWidth: maxWidth, //default 350px, Use a number for pixels, or a string to add units such as rem, or "none".

            //Assessibility requirement: change aria-expanded to aria-selected since expanded isn't supported by assistive technologies
            aria: {
                expanded: false, //Disable default 'aria-expanded'
                content: null, //Disable Tippy management of aria content
            },

            // Debugging (keeps tooltip open)
            // hideOnClick: false, trigger: 'click',

            onShow(instance) {
                instance.reference.setAttribute('aria-selected', 'true');
                instance.setContent(() => {
                    const div = document.createElement('div');
                    div.innerHTML = text; // Set innerHTML to the text (make sure it's safe)
                    return div;
                });
            },
            onHide(instance) {
                instance.reference.setAttribute('aria-selected', 'false');
            },
        });

        // Cleanup function to destroy tooltip instance on unmount
        return () => { instance.destroy(); };
    }, [text, placement]);

    return (
        <span
            ref={tooltipRef}
            tabIndex="0"
            aria-label={text}
            className={className}
        >
            {children}
        </span>
    );
};

export default CustomTooltip;
